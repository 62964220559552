<template>
    <v-container style="padding: 0px;">
        <v-text-field outlined single-line :type="show ? 'text' : 'password'"
                     v-model="text"
                    :label="label"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @input="changeContent" filled dense hide-details="auto"
                    @click:append="show = !show"></v-text-field>
    </v-container>

</template>

<script>
    import Vue from 'vue'
    export default {
        props: {
            name: { type: String, default: "" },
            color: { type: String, default: "" },
            text: { type: String, default: null },
            label: { type: String, default: null },
            validate: { type: Object, default: null },
            formElement: { type: String, default: "" },
            readonly: { type: Boolean, default: false }
        },
        data() {
            return {
                show: false,
                value: null,
                score: 0,
                colorNormal: 'gray',
                colorScore: 'blue',
                hint: this.$t("message.at_least_8_chars")
            }
        },
        created () {
            // this.$validator = this.$parent.$validator
        },
        methods: {
            changeContent(value) {
                this.$emit("input", value)
            }
         },
        computed: {},
        watch: {}
    }

    Vue.component('password');
</script>

<style lang="scss">
</style>
